// 
// Geometry.js
// Cacao
// 
// Created on 9/8/22
// 

class Rect {
  origin = Point.zero;
  size = Size.zero;
  
  static make(x, y, width, height){
    return (new Rect(new Point(x, y), new Size(width, height)));
  }
  
  static from(rect){
    if (!rect) {
      return undefined;
    }
    return Rect.make(rect.origin.x, rect.origin.y, rect.size.width, rect.size.height);
  }
  
  static fromDOMRect(rect){
    const { x, y, width, height } = rect;
    return Rect.make(x, y, width, height);
  };
  
  static get zero(){
    return Rect.make(0, 0, 0, 0);
  }
  
  static get null(){
    return Rect.make(Infinity, Infinity, 0, 0);
  }
  
  constructor(origin, size){
    this.origin = Point.from(origin);
    this.size = Size.from(size);
  }
  
  equals(rect){
    rect = Rect.from(rect);
    return this.origin.x == rect.origin.x &&
           this.origin.y == rect.origin.y &&
           this.size.width == rect.size.width &&
           this.size.height == rect.size.height;
  }
  
  copy(){
    return Rect.make(this.origin.x, this.origin.y, this.size.width, this.size.height);
  }
  
  offsetBy(dx, dy){
    return Rect.make(this.origin.x + dx, this.origin.y + dy, this.size.width, this.size.height);
  }
  
  insetBy(dx, dy){
    return Rect.make(this.origin.x + dx, this.origin.y + dy, this.size.width - 2 * dx, this.size.height - 2 * dy);
  }
  
  insetByEdgeInsets(edgeInsets){
    edgeInsets = EdgeInsets.from(edgeInsets);
    return Rect.make(this.origin.x + edgeInsets.left, this.origin.y + edgeInsets.top, this.size.width - edgeInsets.left - edgeInsets.right, this.size.height - edgeInsets.top - edgeInsets.bottom);
  }
  
  divide(slice, remainder, amount, edge){
    slice.origin = this.origin.copy();
    slice.size = this.size.copy();
    remainder.origin = this.origin.copy();
    remainder.size = this.size.copy();
    
    switch (edge){
      case Edge.minX:
        slice.size.width = amount
        remainder.origin.x += amount
        remainder.size.width -= amount
        break;
      case Edge.maxX:
        slice.origin.x = Rect.from(slice).maxX - amount;
        slice.size.width = amount;
        remainder.size.width -= amount;
        break;
      case Edge.minY:
        slice.size.height = amount;
        remainder.origin.y += amount;
        remainder.size.height -= amount;
        break;
      case Edge.maxY:
        slice.origin.y = Rect.from(slice).maxY - amount;
        slice.size.height = amount;
        remainder.size.height -= amount;
        break;
    }
  }
  
  containsPoint(aPoint){
    return (aPoint.x >= this.origin.x &&
            aPoint.y >= this.origin.y &&
            aPoint.x < this.maxX &&
            aPoint.y < this.maxY);
  }
  
  containsRect(rect){
    const union = this.union(rect);
    return this.equals(union);
  }
  
  intersects(rect){
    const intersection = this.intersection(rect);
    return !intersection.isEmpty;
  }
  
  intersection(rect){
    rect = Rect.from(rect);
    
    const intersection = Rect.make(Math.max(this.minX, rect.minX),
                                   Math.max(this.minY, rect.minY),
                                   0,
                                   0);
    
    intersection.size.width = Math.min(this.maxX, rect.maxX) - intersection.minX;
    intersection.size.height = Math.min(this.maxY, rect.maxY) - intersection.minY;
    
    return intersection.isEmpty ? Rect.zero : intersection;
  }
  
  union(rect){
    rect = Rect.from(rect);
    
    const thisIsNull = this.isNull,
          rectIsNull = !rect || rect.isNull;
    
    if (thisIsNull)
      return rectIsNull ? Rect.null : rect;
    
    if (rectIsNull)
      return thisIsNull ? Rect.null : this.copy();
    
    const minX = Math.min(this.minX, rect.minX),
          minY = Math.min(this.minY, rect.minY),
          maxX = Math.max(this.maxX, rect.maxX),
          maxY = Math.max(this.maxY, rect.maxY);
    
    return Rect.make(minX, minY, maxX - minX, maxY - minY);
  }
  
  get standarized(){
    const { width, height } = this;
    const standardized = this.copy();
    
    if (width < 0.0) {
      standardized.origin.x += width;
      standardized.size.width = -width;
    }
    
    if (height < 0.0) {
      standardized.origin.y += height;
      standardized.size.height = -height;
    }
    
    return standardized;
  }
  
  get integral(){
    const rect = this.standarized;
    
    const x = Math.floor(this.minX),
          y = Math.floor(this.minY);
    
    aRect.size.width = Math.ceil(this.maxX) - x;
    aRect.size.height = Math.ceil(this.maxY) - y;
    aRect.origin.x = x;
    aRect.origin.y = y;
    
    return aRect
  }
  
  get width(){
    return this.size.width;
  }
  
  set width(width){
    this.size.width = width;
  }
  
  get height(){
    return this.size.height;
  }
  
  set height(height){
    this.size.height = height;
  }
  
  get x(){
    return this.origin.x;
  }
  
  set x(x){
    this.origin.x = x;
  }
  
  get y(){
    return this.origin.y;
  }
  
  set y(y){
    this.origin.y = y;
  }
  
  get maxX(){
    return this.origin.x + this.size.width;
  }
  
  get maxY(){
    return this.origin.y + this.size.height;
  }
  
  get midX(){
    return this.origin.x + (this.size.width / 2.0);
  }
  
  get midY(){
    return this.origin.y + (this.size.height / 2.0);
  }
  
  get minX(){
    return this.origin.x;
  }
  
  get minY(){
    return this.origin.y;
  }
  
  get isEmpty(){
    return (this.size.width <= 0.0 || this.size.height <= 0.0 || this.origin.x === Infinity || this.origin.y === Infinity);
  }
  
  get isNull(){
    return (this.origin.x === Infinity || this.origin.y === Infinity);
  }
  
  toString(){
    return `{${this.origin}, ${this.size}}`;
  }
  
  toDOMRect(){
    const { x, y, width, height } = this;
    return new DOMRect(x, y, width, height);
  }
  
}

class Point {
  x = 0;
  y = 0;
  
  static get zero(){
    return (new Point(0, 0));
  }
  
  static from(point){
    return (!point) ? Point.zero : Point.make(point.x, point.y);
  }
  
  static make(x, y){
    return (new Point(x, y));
  }
  
  constructor(x, y){
    this.x = x;
    this.y = y;
  }
  
  equals(point){
    return this.x == point.x && this.y == point.y;
  }
  
  copy(){
    return (new Point(this.x, this.y));
  }
  
  toString(){
    return `{${this.x}, ${this.y}}`;
  }
  
}

class Size {
  width = 0;
  height = 0;
  
  static get zero(){
    return (new Size(0, 0));
  }
  
  static from(size){
    return (!size) ? undefined : Size.make(size.width, size.height);
  }
  
  static make(width, height){
    return (new Size(width, height));
  }
  
  constructor(width, height){
    this.width = width;
    this.height = height;
  }
  
  equals(size){
    return this.width == size.width && this.height == size.height;
  }
  
  copy(){
    return (new Size(this.width, this.height));
  }
  
  toString(){
    return `{${this.width}, ${this.height}}`;
  }
}

class Edge {
  static minX(){
    return 0;
  }
  
  static minY(){
    return 1;
  }
  
  static maxX(){
    return 2;
  }
  
  static maxY(){
    return 3;
  }
}

class EdgeInsets {
  top = 0;
  left = 0;
  bottom = 0;
  right = 0;
  
  static get zero(){
    return (new EdgeInsets(0, 0, 0, 0));
  }
  
  static from(edgeInsets){
    return (!edgeInsets) ? undefined : EdgeInsets.make(edgeInsets.top, edgeInsets.left, edgeInsets.bottom, edgeInsets.right);
  }
  
  static make(top, left, bottom, right){
    return (new EdgeInsets(top, left, bottom, right));
  }
  
  constructor(top = 0, left = 0, bottom = 0, right = 0){
    this.top = top;
    this.left = left;
    this.bottom = bottom;
    this.right = right;
  }
  
  equals(edgeInsets){
    const { top, left, bottom, right } = this;
    return top == edgeInsets.top && left == edgeInsets.left && bottom == edgeInsets.bottom && right == edgeInsets.right;
  }
  
  copy(){
    const { top, left, bottom, right } = this;
    return (new EdgeInsets(top, left, bottom, right));
  }
}

export { Rect, Size, Point, Edge, EdgeInsets };