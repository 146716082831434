const _temp0 = require("../../assets/img/collections/books/ask-iwata.jpg");
const _temp1 = require("../../assets/img/collections/books/brief-answers-to-the-big-questions.jpg");
const _temp2 = require("../../assets/img/collections/books/game-engine-black-book-doom.jpg");
const _temp3 = require("../../assets/img/collections/books/how-to.jpg");
const _temp4 = require("../../assets/img/collections/books/inside-out-a-personal-history-of-pink-floyd.jpg");
const _temp5 = require("../../assets/img/collections/books/jacked-the-outlaw-story-of-grand-theft-auto.jpg");
const _temp6 = require("../../assets/img/collections/books/macintosh-human-interface-guidelines.jpg");
const _temp7 = require("../../assets/img/collections/books/make-something-wonderful.jpg");
const _temp8 = require("../../assets/img/collections/books/masters-of-doom.jpg");
const _temp9 = require("../../assets/img/collections/books/nasa-graphics-standards-manual.jpg");
const _temp10 = require("../../assets/img/collections/books/saucerful-of-secrets-the-pink-floyd-odyssey.jpg");
const _temp11 = require("../../assets/img/collections/books/steve-jobs.jpg");
const _temp12 = require("../../assets/img/collections/books/the-apple-ii-age.jpg");
const _temp13 = require("../../assets/img/collections/books/the-design-of-everyday-things.jpg");
const _temp14 = require("../../assets/img/collections/books/what-if.jpg");

const _temp15 = require("../../assets/img/collections/games/amnesia-the-dark-descent.jpg");
const _temp16 = require("../../assets/img/collections/games/animal-crossing-new-horizons.jpg");
const _temp17 = require("../../assets/img/collections/games/bugs-bunny-lost-in-time.jpg");
const _temp18 = require("../../assets/img/collections/games/captain-toad-treasure-tracker.jpg");
const _temp19 = require("../../assets/img/collections/games/conkers-pocket-tales.jpg");
const _temp20 = require("../../assets/img/collections/games/crash-bandicoot-warped.jpg");
const _temp21 = require("../../assets/img/collections/games/crash-bandicoot.jpg");
const _temp22 = require("../../assets/img/collections/games/crash-team-racing.jpg");
const _temp23 = require("../../assets/img/collections/games/donkey-kong-country-tropical-freeze.jpg");
const _temp24 = require("../../assets/img/collections/games/donkey-kong-country.jpg");
const _temp25 = require("../../assets/img/collections/games/doom-2016.jpg");
const _temp26 = require("../../assets/img/collections/games/doom-3.jpg");
const _temp27 = require("../../assets/img/collections/games/doom.jpg");
const _temp28 = require("../../assets/img/collections/games/duck-hunt.jpg");
const _temp29 = require("../../assets/img/collections/games/firewatch.jpg");
const _temp30 = require("../../assets/img/collections/games/galaga.jpg");
const _temp31 = require("../../assets/img/collections/games/game-watch-gallery-2.jpg");
const _temp32 = require("../../assets/img/collections/games/glider.jpg");
const _temp33 = require("../../assets/img/collections/games/gran-turismo.jpg");
const _temp34 = require("../../assets/img/collections/games/grand-theft-auto-iii.jpg");
const _temp35 = require("../../assets/img/collections/games/grand-theft-auto-iv.jpg");
const _temp36 = require("../../assets/img/collections/games/grand-theft-auto-san-andreas.jpg");
const _temp37 = require("../../assets/img/collections/games/grand-theft-auto-v.jpg");
const _temp38 = require("../../assets/img/collections/games/grand-theft-auto-vice-city-stories.jpg");
const _temp39 = require("../../assets/img/collections/games/grand-theft-auto-vice-city.jpg");
const _temp40 = require("../../assets/img/collections/games/half-life.jpg");
const _temp41 = require("../../assets/img/collections/games/halo-combat-evolved.jpg");
const _temp42 = require("../../assets/img/collections/games/halo-reach.jpg");
const _temp43 = require("../../assets/img/collections/games/jackie-chan-stuntmaster.jpg");
const _temp44 = require("../../assets/img/collections/games/legacy-of-kain-soul-reaver.jpg");
const _temp45 = require("../../assets/img/collections/games/luigis-mansion-3.jpg");
const _temp46 = require("../../assets/img/collections/games/luigis-mansion-dark-moon.jpg");
const _temp47 = require("../../assets/img/collections/games/luigis-mansion.jpg");
const _temp48 = require("../../assets/img/collections/games/machinarium.jpg");
const _temp49 = require("../../assets/img/collections/games/mario-kart-7.jpg");
const _temp50 = require("../../assets/img/collections/games/mario-kart-8.jpg");
const _temp51 = require("../../assets/img/collections/games/mario-kart-super-circuit.jpg");
const _temp52 = require("../../assets/img/collections/games/metroid-dread.jpg");
const _temp53 = require("../../assets/img/collections/games/minecraft.jpg");
const _temp54 = require("../../assets/img/collections/games/need-for-speed-high-stakes.jpg");
const _temp55 = require("../../assets/img/collections/games/need-for-speed-porsche-unleashed.jpg");
const _temp56 = require("../../assets/img/collections/games/nuts-and-milk.jpg");
const _temp57 = require("../../assets/img/collections/games/pokmon-leafgreen.jpg");
const _temp58 = require("../../assets/img/collections/games/portal-2.jpg");
const _temp59 = require("../../assets/img/collections/games/portal.jpg");
const _temp60 = require("../../assets/img/collections/games/quake-4.jpg");
const _temp61 = require("../../assets/img/collections/games/quake-ii.jpg");
const _temp62 = require("../../assets/img/collections/games/quake-iii-arena.jpg");
const _temp63 = require("../../assets/img/collections/games/rayman-2-the-great-escape.jpg");
const _temp64 = require("../../assets/img/collections/games/rollercoaster-tycoon.jpg");
const _temp65 = require("../../assets/img/collections/games/roundabout.jpg");
const _temp66 = require("../../assets/img/collections/games/soccer.jpg");
const _temp67 = require("../../assets/img/collections/games/star-wars-episode-i-the-phantom-menace.jpg");
const _temp68 = require("../../assets/img/collections/games/super-mario-bros.jpg");
const _temp69 = require("../../assets/img/collections/games/super-mario-odyssey.jpg");
const _temp70 = require("../../assets/img/collections/games/tetris-dx.jpg");
const _temp71 = require("../../assets/img/collections/games/tetris.jpg");
const _temp72 = require("../../assets/img/collections/games/the-binding-of-isaac-rebirth.jpg");
const _temp73 = require("../../assets/img/collections/games/the-legend-of-zelda-breath-of-the-wild.jpg");
const _temp74 = require("../../assets/img/collections/games/the-legend-of-zelda-links-awakening.jpg");
const _temp75 = require("../../assets/img/collections/games/the-legend-of-zelda-the-minish-cap.jpg");
const _temp76 = require("../../assets/img/collections/games/the-simpsons-hit-run.jpg");
const _temp77 = require("../../assets/img/collections/games/the-sims.jpg");
const _temp78 = require("../../assets/img/collections/games/the-stanley-parable.jpg");
const _temp79 = require("../../assets/img/collections/games/tony-hawks-pro-skater-2.jpg");
const _temp80 = require("../../assets/img/collections/games/undertale.jpg");
const _temp81 = require("../../assets/img/collections/games/uninvited.jpg");
const _temp82 = require("../../assets/img/collections/games/untitled-goose-game.jpg");

const _temp83 = require("../../assets/img/collections/movies/12-angry-men.jpg");
const _temp84 = require("../../assets/img/collections/movies/a-bugss-life.jpg");
const _temp85 = require("../../assets/img/collections/movies/airplane.jpg");
const _temp86 = require("../../assets/img/collections/movies/alien.jpg");
const _temp87 = require("../../assets/img/collections/movies/back-to-the-future-part-ii.jpg");
const _temp88 = require("../../assets/img/collections/movies/back-to-the-future.jpg");
const _temp89 = require("../../assets/img/collections/movies/blade-runner.jpg");
const _temp90 = require("../../assets/img/collections/movies/coco.jpg");
const _temp91 = require("../../assets/img/collections/movies/creating-rem-lezar.jpg");
const _temp92 = require("../../assets/img/collections/movies/el-agente-topo.jpg");
const _temp93 = require("../../assets/img/collections/movies/frank.jpg");
const _temp94 = require("../../assets/img/collections/movies/her.jpg");
const _temp95 = require("../../assets/img/collections/movies/inception.jpg");
const _temp96 = require("../../assets/img/collections/movies/indiana-jones-and-the-last-crusade.jpg");
const _temp97 = require("../../assets/img/collections/movies/inglourious-basterds.jpg");
const _temp98 = require("../../assets/img/collections/movies/iron-man.jpg");
const _temp99 = require("../../assets/img/collections/movies/jaws.jpg");
const _temp100 = require("../../assets/img/collections/movies/jiro-dreams-of-sushi.jpg");
const _temp101 = require("../../assets/img/collections/movies/john-wick-chapter-2.jpg");
const _temp102 = require("../../assets/img/collections/movies/john-wick-chapter-3-parabellum.jpg");
const _temp103 = require("../../assets/img/collections/movies/john-wick-chapter-4.jpg");
const _temp104 = require("../../assets/img/collections/movies/john-wick.jpg");
const _temp105 = require("../../assets/img/collections/movies/jurassic-park.jpg");
const _temp106 = require("../../assets/img/collections/movies/kill-bill-vol-1.jpg");
const _temp107 = require("../../assets/img/collections/movies/kill-bill-vol-2.jpg");
const _temp108 = require("../../assets/img/collections/movies/look-whos-back.jpg");
const _temp109 = require("../../assets/img/collections/movies/lost-in-translation.jpg");
const _temp110 = require("../../assets/img/collections/movies/once-upon-a-time-in-hollywood.jpg");
const _temp111 = require("../../assets/img/collections/movies/parasite.jpg");
const _temp112 = require("../../assets/img/collections/movies/pink-floyd-the-wall.jpg");
const _temp113 = require("../../assets/img/collections/movies/pirates-of-silicon-valley.jpg");
const _temp114 = require("../../assets/img/collections/movies/pulp-fiction.jpg");
const _temp115 = require("../../assets/img/collections/movies/raiders-of-the-lost-ark.jpg");
const _temp116 = require("../../assets/img/collections/movies/reservoir-dogs.jpg");
const _temp117 = require("../../assets/img/collections/movies/star-wars.jpg");
const _temp118 = require("../../assets/img/collections/movies/terminator-2-judgment-day.jpg");
const _temp119 = require("../../assets/img/collections/movies/the-empire-strikes-back.jpg");
const _temp120 = require("../../assets/img/collections/movies/the-good-the-bad-and-the-ugly.jpg");
const _temp121 = require("../../assets/img/collections/movies/the-incredibles.jpg");
const _temp122 = require("../../assets/img/collections/movies/the-matrix-reloaded.jpg");
const _temp123 = require("../../assets/img/collections/movies/the-matrix.jpg");
const _temp124 = require("../../assets/img/collections/movies/the-parent-trap.jpg");
const _temp125 = require("../../assets/img/collections/movies/the-room.jpg");
const _temp126 = require("../../assets/img/collections/movies/the-sixth-sense.jpg");
const _temp127 = require("../../assets/img/collections/movies/the-terminator.jpg");
const _temp128 = require("../../assets/img/collections/movies/toy-story.jpg");
const _temp129 = require("../../assets/img/collections/movies/up.jpg");
const _temp130 = require("../../assets/img/collections/movies/waynes-world.jpg");
const _temp131 = require("../../assets/img/collections/movies/whiplash.jpg");
const _temp132 = require("../../assets/img/collections/movies/yellow-submarine.jpg");

const _temp133 = require("../../assets/img/collections/music/31-minutos.jpg");
const _temp134 = require("../../assets/img/collections/music/a-kind-of-magic.jpg");
const _temp135 = require("../../assets/img/collections/music/a-saucerful-of-secrets.jpg");
const _temp136 = require("../../assets/img/collections/music/abbey-road.jpg");
const _temp137 = require("../../assets/img/collections/music/all-things-must-pass.jpg");
const _temp138 = require("../../assets/img/collections/music/alturas-de-macchu-picchu.jpg");
const _temp139 = require("../../assets/img/collections/music/amnesiac.jpg");
const _temp140 = require("../../assets/img/collections/music/animals.jpg");
const _temp141 = require("../../assets/img/collections/music/artaud.jpg");
const _temp142 = require("../../assets/img/collections/music/atom-heart-mother.jpg");
const _temp143 = require("../../assets/img/collections/music/autobahn.jpg");
const _temp144 = require("../../assets/img/collections/music/autobanh.jpg");
const _temp145 = require("../../assets/img/collections/music/barrett.jpg");
const _temp146 = require("../../assets/img/collections/music/blackstar.jpg");
const _temp147 = require("../../assets/img/collections/music/broken-china.jpg");
const _temp148 = require("../../assets/img/collections/music/canto-por-travesura.jpg");
const _temp149 = require("../../assets/img/collections/music/dark-side-of-the-moon.jpg");
const _temp150 = require("../../assets/img/collections/music/demon-days.jpg");
const _temp151 = require("../../assets/img/collections/music/die-mensch-maschine.jpg");
const _temp152 = require("../../assets/img/collections/music/discovery.jpg");
const _temp153 = require("../../assets/img/collections/music/endless-summer-vacation.jpg");
const _temp154 = require("../../assets/img/collections/music/eye-in-the-sky.jpg");
const _temp155 = require("../../assets/img/collections/music/fuerza-natural.jpg");
const _temp156 = require("../../assets/img/collections/music/future-nostalgia.jpg");
const _temp157 = require("../../assets/img/collections/music/george-harrison.jpg");
const _temp158 = require("../../assets/img/collections/music/gorillaz.jpg");
const _temp159 = require("../../assets/img/collections/music/hounds-of-love.jpg");
const _temp160 = require("../../assets/img/collections/music/i-robot.jpg");
const _temp161 = require("../../assets/img/collections/music/in-rainbows.jpg");
const _temp162 = require("../../assets/img/collections/music/is-this-the-life-we-really-want.jpg");
const _temp163 = require("../../assets/img/collections/music/les-concerts-en-chine.jpg");
const _temp164 = require("../../assets/img/collections/music/let-it-be.jpg");
const _temp165 = require("../../assets/img/collections/music/lets-dance.jpg");
const _temp166 = require("../../assets/img/collections/music/living-in-the-material-world.jpg");
const _temp167 = require("../../assets/img/collections/music/magical-mystery-tour.jpg");
const _temp168 = require("../../assets/img/collections/music/meddle.jpg");
const _temp169 = require("../../assets/img/collections/music/metropolis-pt-2-scenes-from-a-memory.jpg");
const _temp170 = require("../../assets/img/collections/music/mind-games.jpg");
const _temp171 = require("../../assets/img/collections/music/moon-safari.jpg");
const _temp172 = require("../../assets/img/collections/music/morning-phase.jpg");
const _temp173 = require("../../assets/img/collections/music/motomami.jpg");
const _temp174 = require("../../assets/img/collections/music/mutter.jpg");
const _temp175 = require("../../assets/img/collections/music/nevermind.jpg");
const _temp176 = require("../../assets/img/collections/music/obscured-by-clouds.jpg");
const _temp177 = require("../../assets/img/collections/music/ok-computer.jpg");
const _temp178 = require("../../assets/img/collections/music/plastic-beach.jpg");
const _temp179 = require("../../assets/img/collections/music/plastic-ono-band.jpg");
const _temp180 = require("../../assets/img/collections/music/pulse.jpg");
const _temp181 = require("../../assets/img/collections/music/ray-charles.jpg");
const _temp182 = require("../../assets/img/collections/music/reise-reise.jpg");
const _temp183 = require("../../assets/img/collections/music/revolver.jpg");
const _temp184 = require("../../assets/img/collections/music/roy-pablo.jpg");
const _temp185 = require("../../assets/img/collections/music/sehnsucht.jpg");
const _temp186 = require("../../assets/img/collections/music/sgt-peppers-lonely-hearts-club-band.jpg");
const _temp187 = require("../../assets/img/collections/music/signos.jpg");
const _temp188 = require("../../assets/img/collections/music/solar-power.jpg");
const _temp189 = require("../../assets/img/collections/music/techno-pop.jpg");
const _temp190 = require("../../assets/img/collections/music/the-beatles-1962-1966.jpg");
const _temp191 = require("../../assets/img/collections/music/the-beatles-the-white-album.jpg");
const _temp192 = require("../../assets/img/collections/music/the-division-bell.jpg");
const _temp193 = require("../../assets/img/collections/music/the-piper-at-the-gates-of-dawn.jpg");
const _temp194 = require("../../assets/img/collections/music/the-rutles.jpg");
const _temp195 = require("../../assets/img/collections/music/the-wall.jpg");
const _temp196 = require("../../assets/img/collections/music/thriller.jpg");
const _temp197 = require("../../assets/img/collections/music/toto.jpg");
const _temp198 = require("../../assets/img/collections/music/tour-de-france.jpg");
const _temp199 = require("../../assets/img/collections/music/toxicity.jpg");
const _temp200 = require("../../assets/img/collections/music/trans-europa-express.jpg");
const _temp201 = require("../../assets/img/collections/music/tubular-bells.jpg");
const _temp202 = require("../../assets/img/collections/music/ummagumma.jpg");
const _temp203 = require("../../assets/img/collections/music/viva-chile.jpg");
const _temp204 = require("../../assets/img/collections/music/wet-dream.jpg");
const _temp205 = require("../../assets/img/collections/music/wish-you-were-here.jpg");
const _temp206 = require("../../assets/img/collections/music/yellow-submarine.jpg");

module.exports = {
  "books":   {
    "ask-iwata.jpg": _temp0,
    "brief-answers-to-the-big-questions.jpg": _temp1,
    "game-engine-black-book-doom.jpg": _temp2,
    "how-to.jpg": _temp3,
    "inside-out-a-personal-history-of-pink-floyd.jpg": _temp4,
    "jacked-the-outlaw-story-of-grand-theft-auto.jpg": _temp5,
    "macintosh-human-interface-guidelines.jpg": _temp6,
    "make-something-wonderful.jpg": _temp7,
    "masters-of-doom.jpg": _temp8,
    "nasa-graphics-standards-manual.jpg": _temp9,
    "saucerful-of-secrets-the-pink-floyd-odyssey.jpg": _temp10,
    "steve-jobs.jpg": _temp11,
    "the-apple-ii-age.jpg": _temp12,
    "the-design-of-everyday-things.jpg": _temp13,
    "what-if.jpg": _temp14
  },
  "games":   {
    "amnesia-the-dark-descent.jpg": _temp15,
    "animal-crossing-new-horizons.jpg": _temp16,
    "bugs-bunny-lost-in-time.jpg": _temp17,
    "captain-toad-treasure-tracker.jpg": _temp18,
    "conkers-pocket-tales.jpg": _temp19,
    "crash-bandicoot-warped.jpg": _temp20,
    "crash-bandicoot.jpg": _temp21,
    "crash-team-racing.jpg": _temp22,
    "donkey-kong-country-tropical-freeze.jpg": _temp23,
    "donkey-kong-country.jpg": _temp24,
    "doom-2016.jpg": _temp25,
    "doom-3.jpg": _temp26,
    "doom.jpg": _temp27,
    "duck-hunt.jpg": _temp28,
    "firewatch.jpg": _temp29,
    "galaga.jpg": _temp30,
    "game-watch-gallery-2.jpg": _temp31,
    "glider.jpg": _temp32,
    "gran-turismo.jpg": _temp33,
    "grand-theft-auto-iii.jpg": _temp34,
    "grand-theft-auto-iv.jpg": _temp35,
    "grand-theft-auto-san-andreas.jpg": _temp36,
    "grand-theft-auto-v.jpg": _temp37,
    "grand-theft-auto-vice-city-stories.jpg": _temp38,
    "grand-theft-auto-vice-city.jpg": _temp39,
    "half-life.jpg": _temp40,
    "halo-combat-evolved.jpg": _temp41,
    "halo-reach.jpg": _temp42,
    "jackie-chan-stuntmaster.jpg": _temp43,
    "legacy-of-kain-soul-reaver.jpg": _temp44,
    "luigis-mansion-3.jpg": _temp45,
    "luigis-mansion-dark-moon.jpg": _temp46,
    "luigis-mansion.jpg": _temp47,
    "machinarium.jpg": _temp48,
    "mario-kart-7.jpg": _temp49,
    "mario-kart-8.jpg": _temp50,
    "mario-kart-super-circuit.jpg": _temp51,
    "metroid-dread.jpg": _temp52,
    "minecraft.jpg": _temp53,
    "need-for-speed-high-stakes.jpg": _temp54,
    "need-for-speed-porsche-unleashed.jpg": _temp55,
    "nuts-and-milk.jpg": _temp56,
    "pokmon-leafgreen.jpg": _temp57,
    "portal-2.jpg": _temp58,
    "portal.jpg": _temp59,
    "quake-4.jpg": _temp60,
    "quake-ii.jpg": _temp61,
    "quake-iii-arena.jpg": _temp62,
    "rayman-2-the-great-escape.jpg": _temp63,
    "rollercoaster-tycoon.jpg": _temp64,
    "roundabout.jpg": _temp65,
    "soccer.jpg": _temp66,
    "star-wars-episode-i-the-phantom-menace.jpg": _temp67,
    "super-mario-bros.jpg": _temp68,
    "super-mario-odyssey.jpg": _temp69,
    "tetris-dx.jpg": _temp70,
    "tetris.jpg": _temp71,
    "the-binding-of-isaac-rebirth.jpg": _temp72,
    "the-legend-of-zelda-breath-of-the-wild.jpg": _temp73,
    "the-legend-of-zelda-links-awakening.jpg": _temp74,
    "the-legend-of-zelda-the-minish-cap.jpg": _temp75,
    "the-simpsons-hit-run.jpg": _temp76,
    "the-sims.jpg": _temp77,
    "the-stanley-parable.jpg": _temp78,
    "tony-hawks-pro-skater-2.jpg": _temp79,
    "undertale.jpg": _temp80,
    "uninvited.jpg": _temp81,
    "untitled-goose-game.jpg": _temp82
  },
  "movies":   {
    "12-angry-men.jpg": _temp83,
    "a-bugss-life.jpg": _temp84,
    "airplane.jpg": _temp85,
    "alien.jpg": _temp86,
    "back-to-the-future-part-ii.jpg": _temp87,
    "back-to-the-future.jpg": _temp88,
    "blade-runner.jpg": _temp89,
    "coco.jpg": _temp90,
    "creating-rem-lezar.jpg": _temp91,
    "el-agente-topo.jpg": _temp92,
    "frank.jpg": _temp93,
    "her.jpg": _temp94,
    "inception.jpg": _temp95,
    "indiana-jones-and-the-last-crusade.jpg": _temp96,
    "inglourious-basterds.jpg": _temp97,
    "iron-man.jpg": _temp98,
    "jaws.jpg": _temp99,
    "jiro-dreams-of-sushi.jpg": _temp100,
    "john-wick-chapter-2.jpg": _temp101,
    "john-wick-chapter-3-parabellum.jpg": _temp102,
    "john-wick-chapter-4.jpg": _temp103,
    "john-wick.jpg": _temp104,
    "jurassic-park.jpg": _temp105,
    "kill-bill-vol-1.jpg": _temp106,
    "kill-bill-vol-2.jpg": _temp107,
    "look-whos-back.jpg": _temp108,
    "lost-in-translation.jpg": _temp109,
    "once-upon-a-time-in-hollywood.jpg": _temp110,
    "parasite.jpg": _temp111,
    "pink-floyd-the-wall.jpg": _temp112,
    "pirates-of-silicon-valley.jpg": _temp113,
    "pulp-fiction.jpg": _temp114,
    "raiders-of-the-lost-ark.jpg": _temp115,
    "reservoir-dogs.jpg": _temp116,
    "star-wars.jpg": _temp117,
    "terminator-2-judgment-day.jpg": _temp118,
    "the-empire-strikes-back.jpg": _temp119,
    "the-good-the-bad-and-the-ugly.jpg": _temp120,
    "the-incredibles.jpg": _temp121,
    "the-matrix-reloaded.jpg": _temp122,
    "the-matrix.jpg": _temp123,
    "the-parent-trap.jpg": _temp124,
    "the-room.jpg": _temp125,
    "the-sixth-sense.jpg": _temp126,
    "the-terminator.jpg": _temp127,
    "toy-story.jpg": _temp128,
    "up.jpg": _temp129,
    "waynes-world.jpg": _temp130,
    "whiplash.jpg": _temp131,
    "yellow-submarine.jpg": _temp132
  },
  "music":   {
    "31-minutos.jpg": _temp133,
    "a-kind-of-magic.jpg": _temp134,
    "a-saucerful-of-secrets.jpg": _temp135,
    "abbey-road.jpg": _temp136,
    "all-things-must-pass.jpg": _temp137,
    "alturas-de-macchu-picchu.jpg": _temp138,
    "amnesiac.jpg": _temp139,
    "animals.jpg": _temp140,
    "artaud.jpg": _temp141,
    "atom-heart-mother.jpg": _temp142,
    "autobahn.jpg": _temp143,
    "autobanh.jpg": _temp144,
    "barrett.jpg": _temp145,
    "blackstar.jpg": _temp146,
    "broken-china.jpg": _temp147,
    "canto-por-travesura.jpg": _temp148,
    "dark-side-of-the-moon.jpg": _temp149,
    "demon-days.jpg": _temp150,
    "die-mensch-maschine.jpg": _temp151,
    "discovery.jpg": _temp152,
    "endless-summer-vacation.jpg": _temp153,
    "eye-in-the-sky.jpg": _temp154,
    "fuerza-natural.jpg": _temp155,
    "future-nostalgia.jpg": _temp156,
    "george-harrison.jpg": _temp157,
    "gorillaz.jpg": _temp158,
    "hounds-of-love.jpg": _temp159,
    "i-robot.jpg": _temp160,
    "in-rainbows.jpg": _temp161,
    "is-this-the-life-we-really-want.jpg": _temp162,
    "les-concerts-en-chine.jpg": _temp163,
    "let-it-be.jpg": _temp164,
    "lets-dance.jpg": _temp165,
    "living-in-the-material-world.jpg": _temp166,
    "magical-mystery-tour.jpg": _temp167,
    "meddle.jpg": _temp168,
    "metropolis-pt-2-scenes-from-a-memory.jpg": _temp169,
    "mind-games.jpg": _temp170,
    "moon-safari.jpg": _temp171,
    "morning-phase.jpg": _temp172,
    "motomami.jpg": _temp173,
    "mutter.jpg": _temp174,
    "nevermind.jpg": _temp175,
    "obscured-by-clouds.jpg": _temp176,
    "ok-computer.jpg": _temp177,
    "plastic-beach.jpg": _temp178,
    "plastic-ono-band.jpg": _temp179,
    "pulse.jpg": _temp180,
    "ray-charles.jpg": _temp181,
    "reise-reise.jpg": _temp182,
    "revolver.jpg": _temp183,
    "roy-pablo.jpg": _temp184,
    "sehnsucht.jpg": _temp185,
    "sgt-peppers-lonely-hearts-club-band.jpg": _temp186,
    "signos.jpg": _temp187,
    "solar-power.jpg": _temp188,
    "techno-pop.jpg": _temp189,
    "the-beatles-1962-1966.jpg": _temp190,
    "the-beatles-the-white-album.jpg": _temp191,
    "the-division-bell.jpg": _temp192,
    "the-piper-at-the-gates-of-dawn.jpg": _temp193,
    "the-rutles.jpg": _temp194,
    "the-wall.jpg": _temp195,
    "thriller.jpg": _temp196,
    "toto.jpg": _temp197,
    "tour-de-france.jpg": _temp198,
    "toxicity.jpg": _temp199,
    "trans-europa-express.jpg": _temp200,
    "tubular-bells.jpg": _temp201,
    "ummagumma.jpg": _temp202,
    "viva-chile.jpg": _temp203,
    "wet-dream.jpg": _temp204,
    "wish-you-were-here.jpg": _temp205,
    "yellow-submarine.jpg": _temp206
  }
}