import { View } from "cacao/ui";

class MarqueeLabel extends View {
  
  constructor() {
    super();
    
    this.node.className = "marquee-container";
    
    const wrapperNode = document.createElement("span");
    wrapperNode.className = "marquee-text-wrapper";
    
    this.wrapperNode = wrapperNode;
    this.node.appendChild(wrapperNode);
    
    this._text = "";
    
    this.textNodes = [];
    this.containerMetrics = {};
    this.configure();
    
    const invalidateLayout = () => {
      if (this.willLayout) {
        return;
      }
      
      this.willLayout = true;
      
      window.requestAnimationFrame(() => {
        this._hasDirtyMetrics = true;
        this.layout();
        this.willLayout = false;
      });
    };
    
    window.addEventListener("resize", invalidateLayout);
    invalidateLayout();
  }
  
  layout(){
    const { node, textNodes, containerMetrics } = this;
    
    const firstNode = textNodes[0];
    const boundsForFirstNode = firstNode.getBoundingClientRect();
    const textWidth = Math.ceil(boundsForFirstNode.width);
    
    this._hasDirtyMetrics |= (containerMetrics.parentWidth <= 0);
    
    if (this._hasDirtyMetrics) {
      const style = window.getComputedStyle(node);
      const metrics = {
        parentWidth: node.getBoundingClientRect().width,
        paddingLeft: parseFloat(style.paddingLeft),
        paddingRight: parseFloat(style.paddingRight),
        interSpacing: 32
      };
      
      Object.assign(containerMetrics, metrics);
      
      this._hasDirtyMetrics = false;
    }
    
    const { interSpacing, parentWidth, paddingLeft, paddingRight } = containerMetrics; 
    const availableWidth = (parentWidth - paddingLeft - paddingRight);
    const marqueeRequired = (textWidth > availableWidth);
    
    textNodes.forEach((node, index) => {
      const visible = marqueeRequired || (index == 0);
      
      node.style.visibility = !visible ? "hidden" : "";
    });
    
    const distance = textWidth + interSpacing;
    const centerLeftMargin = Math.floor((parentWidth - textWidth) / 2);
    
    // Apply center left margin to first node:
    firstNode.style.marginLeft = `${Math.max(centerLeftMargin - interSpacing, 0)}px`
    
    if (marqueeRequired) {
      this.stop();
      
      const keyframes = [
        {
          transform: new DOMMatrix()
        },
        
        {
          transform: new DOMMatrix().translate(distance * -1, 0)
        }
      ];
      
      const speed = 80; // `px` per second
      const duration = distance / speed * 1000;
      
      const options = {
        easing: "linear",
        duration
      };
      
      const delayForStart = 1000;
      const delayForRepeat = 5000;
      
      const animate = () => {
        const animation = this.wrapperNode.animate(keyframes, options)
        animation.onfinish = () => {
          this.scheduledAnimate = setTimeout(animate, delayForRepeat);
        };
        
        this.animation = animation;
      };
      
      this.scheduledAnimate = setTimeout(animate, delayForStart);
    } else {
      this.stop();
    }
  }
  
  stop(){
    if (this.animation) {
      this.animation.cancel();
      this.animation = undefined;
    }
    
    if (this.scheduledAnimate) {
      window.clearTimeout(this.scheduledAnimate);
    }
  }
  
  configure(){
    const requiredTextNodeCount = 2;
    if (this.textNodes.length != requiredTextNodeCount) {
      const textNodes = [];
      const fragment = document.createDocumentFragment();
      
      for (let idx = 0; idx < requiredTextNodeCount; idx += 1) {
        const textNode = document.createElement("span");
        textNode.className = "marquee-repeating";
        
        textNodes.push(textNode);
        fragment.appendChild(textNode);
      }
      
      this.wrapperNode.innerHTML = "";
      this.wrapperNode.appendChild(fragment);
      
      this.textNodes = textNodes;
    }
    
    const { text, textNodes } = this;
    textNodes.forEach(node => node.textContent = text);
  }
  
  set text(text){
    if (!text) {
      text = "";
    }
    
    if (text !== this.text) {
      this._text = text;
      
      this.stop();
      this.configure();
      this.layout();
    }
  }
  
  get text(){
    return this._text;
  }
  
}

export default MarqueeLabel;
