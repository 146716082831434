import { App } from "cacao/ui";
import RootView from "./views/RootView";
import LibraryCollectionView from "./views/LibraryCollectionView";
import { DirectoryData, Preloader } from "./assets/index";

class Directory extends App {
  
  documentDidLoad(){
    // Configure root:
    const root = new RootView({ destinations: DirectoryData.destinations });
    root.didSelectURL = (url) => {
      this.handleURL(url, { animated: true });
    };
    
    const containerNode = document.body;
    containerNode.style.visibility = "hidden";
    
    containerNode.appendChild(root.node);
    
    this.rootView = root;
    
    // Configure preloader:
    const preloader = new Preloader();
    const prepare = async () => {
      try {
        const fonts = preloader.fonts();
        const springboardIcons = preloader.imagesContainedInElement(root.springboardView.node);
        
        await Promise.all([ fonts, springboardIcons ]);
      } catch (error) {
        console.warn("An error was encountered when preloading", error);
      }
      
      window.requestAnimationFrame(() => {
        containerNode.style.visibility = "";
        root.animateSpringboardIn();
      });
    };
    
    prepare();
    
    // Resolve path:
    const path = window.location.pathname;
    if (path !== "/") {
      this.handleURL(path, { animated: false });
    }
  }
  
  handleURL(url, { animated = false } = options){
    let urlObj;
    try {
      urlObj = new URL(url, window.location.origin);
    } catch (e) {
      console.error("Invalid URL:", e);
      return;
    }
    
    // Handle external URLs:
    if (urlObj.host !== window.location.host) {
      this.openURL(url);
      return;
    }
    
    // Handle path:
    const { pathname } = urlObj;
    
    // Root:
    if (pathname === "/") {
      this.rootView.dismiss({ animated });
      
      history.replaceState({ path: url }, DirectoryData.title, url);
      document.title = DirectoryData.title;
      
      this.setPrefersLightTheme(false, { animated });
    }
    // Path:
    else if (pathname.startsWith("/")) {
      const normalizedPathname = pathname.replace(/\/+$/, "");
      const collection = DirectoryData.collections.filter(item => `/${item.name.toLowerCase()}` === normalizedPathname)[0];
      
      if (collection) {
        const view = new LibraryCollectionView(collection);
        view.navigationBar.didSelectClose = () => {
          this.handleURL("/", { animated: true });
        };
        
        if (collection["prefers-light-theme"]) {
          this.setPrefersLightTheme(true, { animated });
        }
        
        this.rootView.present(view, { animated });
        
        history.replaceState({ path: url }, collection.title, url);
        document.title = `${collection.title} — ${DirectoryData.title}`;
      } else {
        // TODO: 404
        this.handleURL("/", { animated });
      }
    } else {
      console.warn("Ignored URL", url);
    }
  }
  
  openURL(url){
    // Note: window.open() sucks, this works better:
    const a = document.createElement("a");
    a.style.position = "fixed";
    a.target = "_blank";
    a.href = url;
    
    const containerNode = document.body;
    containerNode.appendChild(a);
    a.click();
    containerNode.removeChild(a);
  }
  
  setPrefersLightTheme(prefersLightTheme, { animated } = options){
    const name = [ "--theme-color", "--theme-color-light" ][prefersLightTheme | 0];
    const themeColor = window.getComputedStyle(document.documentElement).getPropertyValue(name);
    
    if (animated) {
      const options = RootView.defaultAnimationOptions;
      const animation = document.body.animate([
        {
          backgroundColor: themeColor
        }
      ], options);
      
      const completion = () => {
        document.body.style.backgroundColor = themeColor;
      };
      
      animation.finished.then(completion);
    } else {
      document.body.style.backgroundColor = themeColor;
    }
  }
  
}

window.app = new Directory(document);
