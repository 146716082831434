import { View } from "cacao/ui";

class RootHeaderView extends View {
  
  makeNode(document){
    return document.createElement("header");
  }
  
  constructor(){
    super();
    
    this.node.innerHTML = `
      <figure class="mark"></figure>
      
      <nav>
        <a href="https://matias.me/" target="_blank">Website</a>
      </nav>
    `;
  }
  
}

export default RootHeaderView;
