import data from "/directory.yaml"
import slugify from "/src/slugify";

const convertedLinks = data.links.map(link => {
  const title = Object.keys(link)[0];
  const url = link[title];
  return { title, url };
});

const convertedCollections = data.collections.map(collection => {
  const title = collection.name;
  const url = `/${slugify(title)}/`;
  return { title, url };
});

const root = {
  destinations: [].concat(convertedLinks, convertedCollections),
  ...data
};

export default root;
