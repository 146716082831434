title: Matías Martínez's Directory
description: A directory of links and things.

links:
  - Twitter: https://twitter.com/martinez
  - Mastodon: https://mastodon.social/@martinez
  - Instagram: https://www.instagram.com/matmartinez
  - Threads: https://www.threads.net/@matmartinez
  - YouTube: https://www.youtube.com/c/MatíasM
  - Github: https://github.com/matmartinez
  - Last.fm: https://www.last.fm/user/martinezmatias
  - TikTok: https://www.tiktok.com/@matias.meme
  - Dribbble: https://dribbble.com/matmartinez
  - Mail: mailto:hello@matias.me

collections:
  - name: Music
    title: Music I like
    id: music
    prefers-light-theme: true
    
    collection:
      
      - title: Moon Safari
        author: Air
        year: 1998
        url: https://music.apple.com/us/album/moon-safari/693063670
      
      - title: Eye In The Sky
        author: The Alan Parsons Project
        year: 1982
        url: https://music.apple.com/us/album/eye-in-the-sky/1205670750
      
      - title: I Robot
        author: The Alan Parsons Project
        year: 1977
        url: https://music.apple.com/us/album/i-robot/1240904821
      
      - title: The Beatles / 1962-1966
        author: The Beatles
        year: 1973
        url: https://music.apple.com/us/album/the-beatles-1962-1966-the-red-album/1441132965
      
      - title: Magical Mystery Tour
        author: The Beatles
        year: 1967
        url: https://music.apple.com/us/album/magical-mystery-tour/1441163490
      
      - title: Yellow Submarine
        author: The Beatles
        year: 1969
        url: https://music.apple.com/us/album/yellow-submarine/1441164525
      
      - title: Revolver
        author: The Beatles
        year: 1966
        url: https://music.apple.com/us/album/revolver/1441164670
      
      - title: Sgt. Pepper's Lonely Hearts Club Band
        author: The Beatles
        year: 1967
        url: https://music.apple.com/us/album/sgt-peppers-lonely-hearts-club-band-deluxe-edition/1440881011
      
      - title: The Beatles (The White Album)
        author: The Beatles
        year: 1968
        url: https://music.apple.com/us/album/sgt-peppers-lonely-hearts-club-band-deluxe-edition/1440881011
      
      - title: Let It Be
        author: The Beatles
        year: 1970
        url: https://music.apple.com/us/album/let-it-be/1441164495
      
      - title: Abbey Road
        author: The Beatles
        year: 1969
        url: https://music.apple.com/us/album/abbey-road-super-deluxe-edition-2019-remix-remaster/1474833332
      
      - title: Mind Games
        author: John Lennon
        year: 1973
        url: https://music.apple.com/us/album/mind-games/1440854146
      
      - title: Morning Phase
        author: Beck
        year: 2014
        url: https://music.apple.com/us/album/morning-phase/797897966
      
      - title: Roy Pablo
        author: boy pablo
        year: 2017
        url: https://music.apple.com/us/album/roy-pablo-ep/1234864053
      
      - title: Discovery
        author: Daft Punk
        year: 2001
        url: https://music.apple.com/us/album/discovery/697194953
      
      - title: Let's Dance
        author: David Bowie
        year: 1983
        url: https://music.apple.com/us/album/lets-dance-2018-remaster/1475002920
      
      - title: Blackstar
        author: David Bowie
        year: 2016
        url: https://music.apple.com/us/album/lets-dance-2018-remaster/1475002920
      
      - title: "Metropolis, Pt. 2: Scenes from a Memory"
        author: Dream Theater
        year: 1999
        url: https://music.apple.com/us/album/lets-dance-2018-remaster/1475002920
      
      - title: Future Nostalgia
        author: Dua Lipa
        year: 2020
        url: https://music.apple.com/us/album/future-nostalgia/1538003494
      
      - title: ¡Viva Chile!
        author: Electrodomésticos
        year: 1986
        url: https://music.apple.com/us/album/viva-chile/1483607881
      
      - title: All Things Must Pass
        author: George Harrison
        year: 1970
        url: https://music.apple.com/us/album/all-things-must-pass-50th-anniversary-super-deluxe/1666771841
      
      - title: Living in the Material World
        author: George Harrison
        year: 1973
        url: https://music.apple.com/us/album/living-in-the-material-world/1666653516
      
      - title: George Harrison
        author: George Harrison
        year: 1979
        url: https://music.apple.com/us/album/george-harrison/1666655988
      
      - title: Gorillaz
        author: Gorillaz
        year: 2001
        url: https://music.apple.com/us/album/gorillaz/850576570
      
      - title: Demon Days
        author: Gorillaz
        year: 2005
        url: https://music.apple.com/us/album/demon-days/850571319
      
      - title: Plastic Beach
        author: Gorillaz
        year: 2010
        url: https://music.apple.com/us/album/plastic-beach-deluxe-version/859841880
      
      - title: Fuerza Natural
        author: Gustavo Cerati
        year: 2009
        url: https://music.apple.com/us/album/fuerza-natural/1358493163
      
      - title: Les concerts en Chine
        author: Jean-Michel Jarre
        year: 1982
        url: https://music.apple.com/us/album/les-concerts-en-chine-1981/1719813501
      
      - title: Hounds of Love
        author: Kate Bush
        year: 1985
        url: https://music.apple.com/us/album/hounds-of-love-2018-remaster/1675560565
      
      - title: Autobahn
        author: Kraftwerk
        year: 1974
        url: https://music.apple.com/us/album/autobahn-remastered/726154713
      
      - title: Trans-Europa Express
        author: Kraftwerk
        year: 1977
        url: https://music.apple.com/us/album/trans-europe-express-2009-remaster/726144557
      
      - title: Die Mensch-Maschine
        author: Kraftwerk
        year: 1978
        url: https://music.apple.com/us/album/the-man-machine-remastered/726157248
      
      - title: Techno Pop
        author: Kraftwerk
        year: 1986
        url: https://music.apple.com/us/album/the-man-machine-remastered/726157248
      
      - title: Tour de France
        author: Kraftwerk
        year: 2003
        url: https://music.apple.com/us/album/tour-de-france-remastered/726341054
      
      - title: Solar Power
        author: Lorde
        year: 2021
        url: https://music.apple.com/us/album/solar-power/1572940891
      
      - title: Alturas de Macchu Picchu
        author: Los Jaivas
        year: 1981
        url: https://music.apple.com/us/album/alturas-de-macchu-picchu/1607400773
      
      - title: Thriller
        author: Michael Jackson
        year: 1982
        url: https://music.apple.com/us/album/thriller/269572838
      
      - title: Tubular Bells
        author: Mike Oldfield
        year: 1973
        url: https://music.apple.com/us/album/tubular-bells/994663630
      
      - title: Endless Summer Vacation
        author: Miley Cyrus
        year: 2023
        url: https://music.apple.com/us/album/tubular-bells/994663630
      
      - title: Nevermind
        author: Nirvana
        year: 1991
        url: https://music.apple.com/us/album/endless-summer-vacation/1702906527
      
      - title: Artaud
        author: Pescado Rabioso
        year: 1973
        url: https://music.apple.com/us/album/artaud/321645242
      
      - title: The Piper at the Gates of Dawn
        author: Pink Floyd
        year: 1967
        url: https://music.apple.com/us/album/the-piper-at-the-gates-of-dawn/1065975041
      
      - title: A Saucerful of Secrets
        author: Pink Floyd
        year: 1968
        url: https://music.apple.com/us/album/a-saucerful-of-secrets/1065974579
      
      - title: Ummagumma
        author: Pink Floyd
        year: 1969
        url: https://music.apple.com/us/album/ummagumma/1065978159
      
      - title: Atom Heart Mother
        author: Pink Floyd
        year: 1970
        url: https://music.apple.com/us/album/atom-heart-mother/1065975345
      
      - title: Meddle
        author: Pink Floyd
        year: 1971
        url: https://music.apple.com/us/album/meddle-2016-remaster/1065973614
      
      - title: Obscured by Clouds
        author: Pink Floyd
        year: 1972
        url: https://music.apple.com/us/album/obscured-by-clouds/1065975350
      
      - title: Dark Side of the Moon
        author: Pink Floyd
        year: 1973
        url: https://music.apple.com/us/album/obscured-by-clouds/1065975350
      
      - title: Wish You Were Here
        author: Pink Floyd
        year: 1975
        url: https://music.apple.com/us/album/wish-you-were-here/1065973975
      
      - title: Animals
        author: Pink Floyd
        year: 1977
        url: https://music.apple.com/us/album/animals/1065974284
      
      - title: Wet Dream
        author: Richard Wright
        year: 1978
        url: https://music.apple.com/us/album/wet-dream-2023-remix/1693925254
      
      - title: The Wall
        author: Pink Floyd
        year: 1979
        url: https://music.apple.com/us/album/wet-dream-2023-remix/1693925254
      
      - title: The Division Bell
        author: Pink Floyd
        year: 1994
        url: https://music.apple.com/us/album/wet-dream-2023-remix/1693925254
      
      - title: Pulse
        author: Pink Floyd
        year: 1995
        url: https://music.apple.com/us/album/wet-dream-2023-remix/1693925254
      
      - title: Broken China
        author: Richard Wright
        year: 1996
        url: https://en.wikipedia.org/wiki/Broken_China
      
      - title: A Kind of Magic
        author: Queen
        year: 1986
        url: https://music.apple.com/us/album/a-kind-of-magic/1440783166
      
      - title: Amnesiac
        author: Radiohead
        year: 2001
        url: https://music.apple.com/us/album/amnesiac/1097864180
      
      - title: In Rainbows
        author: Radiohead
        year: 2007
        url: https://music.apple.com/us/album/in-rainbows/1109714933
      
      - title: OK Computer
        author: Radiohead
        year: 1997
        url: https://music.apple.com/us/album/ok-computer/1097861387
      
      - title: Sehnsucht
        author: Rammstein
        year: 1997
        url: https://music.apple.com/us/album/sehnsucht/1390562159
      
      - title: Mutter
        author: Rammstein
        year: 2001
        url: https://music.apple.com/us/album/mutter/1440770702
      
      - title: Reise, Reise
        author: Rammstein
        year: 2004
        url: https://music.apple.com/us/album/reise-reise/1440734479
      
      - title: Ray Charles
        author: Ray Charles
        year: 1957
        url: https://music.apple.com/us/album/ray-charles/46592114
      
      - title: Is This the Life We Really Want?
        author: Roger Waters
        year: 2017
        url: https://music.apple.com/us/album/is-this-the-life-we-really-want/1227077689
      
      - title: MOTOMAMI
        author: ROSALÍA
        year: 2022
        url: https://music.apple.com/us/album/motomami/1607918350
      
      - title: The Rutles
        author: The Rutles
        year: 1978
        url: https://music.apple.com/us/album/the-rutles/335832270
      
      - title: Signos
        author: Soda Stereo
        year: 1986
        url: https://music.apple.com/us/album/signos/321882453
      
      - title: Barrett
        author: Syd Barrett
        year: 1970
        url: https://music.apple.com/us/album/barrett/1111645897
      
      - title: Toxicity
        author: System Of A Down
        year: 2001
        url: https://music.apple.com/us/album/toxicity/273714443
      
      - title: Toto
        author: Toto
        year: 1978
        url: https://music.apple.com/us/album/toto/198226130
      
      - title: Canto por Travesura
        author: Victor Jara
        year: 1973
        url: https://music.apple.com/us/album/canto-por-travesura/1446799751
      
      - title: Plastic Ono Band
        author: Yoko Ono
        year: 1970
        url: https://music.apple.com/us/album/yoko-ono-plastic-ono-band-deluxe-edition/1149642244
      
      - title: 31 Minutos
        author: 31 Minutos
        year: 2003
        url: https://music.apple.com/us/album/31-minutos/1687546227
      
  
  - name: Movies
    title: Movies I like
    id: movies
    
    collection:
      - title: The Matrix
        author: Lana & Lilly Wachowski
        year: 1999
        url: https://www.imdb.com/title/tt0133093/
      
      - title: The Matrix Reloaded
        author: Lana & Lilly Wachowski
        year: 2003
        url: https://www.imdb.com/title/tt0234215/
        
      - title: Frank
        author: Lenny Abrahamson
        year: 2014
        url: https://www.imdb.com/title/tt1605717/
      
      - title: Yellow Submarine
        author: George Dunning
        year: 1968
        url: https://www.imdb.com/title/tt0063823/
      
      - title: Indiana Jones and the Last Crusade
        author: Steven Spielberg
        year: 1988
        url: https://www.imdb.com/title/tt0097576
        
      - title: Raiders of the Lost Ark
        author: Steven Spielberg
        year: 1981
        url: https://www.imdb.com/title/tt0082971/
        
      - title: Jaws
        author: Steven Spielberg
        year: 1975
        url: https://www.imdb.com/title/tt0073195/
      
      - title: Jurassic Park
        author: Steven Spielberg
        year: 1993
        url: https://www.imdb.com/title/tt0107290/
      
      - title: Back to the Future
        author: Robert Zemeckis
        year: 1985
        url: https://www.imdb.com/title/tt0060196/
      
      - title: Back to the Future Part II
        author: Robert Zemeckis
        year: 1989
        url: https://www.imdb.com/title/tt0096874/
        
      - title: Jiro Dreams of Sushi
        author: David Gelb
        year: 2011
        url: https://www.imdb.com/title/tt0060196/
      
      - title: Whiplash
        author: Damien Chazelle
        year: 2014
        url: https://www.imdb.com/title/tt2582802/
      
      - title: The Sixth Sense
        author: M. Night Shyamalan
        year: 1999
        url: https://www.imdb.com/title/tt2582802/
        
      - title: Reservoir Dogs
        author: Quentin Tarantino
        year: 1992
        url: https://www.imdb.com/title/tt0105236/
      
      - title: Pulp Fiction
        author: Quentin Tarantino
        year: 1994
        url: https://www.imdb.com/title/tt0110912/
      
      - title: "Kill Bill: Vol. 1"
        author: Quentin Tarantino
        year: 2003
        url: https://www.imdb.com/title/tt0266697/
      
      - title: "Kill Bill: Vol. 2"
        author: Quentin Tarantino
        year: 2004
        url: https://www.imdb.com/title/tt0378194/
      
      - title: Inglourious Basterds
        author: Quentin Tarantino
        year: 2009
        url: https://www.imdb.com/title/tt0361748/
      
      - title: Once Upon a Time in Hollywood
        author: Quentin Tarantino
        year: 2019
        url: https://www.imdb.com/title/tt7131622/
        
      - title: John Wick
        author: Chad Stahelski
        year: 2014
        url: https://www.imdb.com/title/tt2911666/
      
      - title: "John Wick: Chapter 2"
        author: Chad Stahelski
        year: 2017
        url: https://www.imdb.com/title/tt4425200/
        
      - title: "John Wick: Chapter 3 - Parabellum"
        author: Chad Stahelski
        year: 2019
        url: https://www.imdb.com/title/tt6146586/
      
      - title: "John Wick: Chapter 4"
        author: Chad Stahelski
        year: 2023
        url: https://www.imdb.com/title/tt10366206/
      
      - title: "Look Who's Back"
        author: David Wnendt
        year: 2015
        url: https://www.imdb.com/title/tt4176826/
      
      - title: Lost in Translation
        author: Sofia Coppola
        year: 2003
        url: https://www.imdb.com/title/tt0335266/
      
      - title: Her
        author: Spike Jonze
        year: 2013
        url: https://www.imdb.com/title/tt1798709/
      
      - title: Parasite
        author: Bong Joon-ho
        year: 2019
        url: https://www.imdb.com/title/tt6751668/
      
      - title: The Parent Trap
        author: Nancy Meyers
        year: 1998
        url: https://www.imdb.com/title/tt0120783/
      
      - title: "Pink Floyd: The Wall"
        author: Alan Parker
        year: 1982
        url: https://www.imdb.com/title/tt0084503/
        
      - title: Pirates of Silicon Valley
        author: Martyn Burke
        year: 1999
        url: https://www.imdb.com/title/tt0168122/
        
      - title: Iron Man
        author: Jon Favreau
        year: 2008
        url: https://www.imdb.com/title/tt0371746/
      
      - title: The Room
        author: Tommy Wiseau
        year: 2003
        url: https://www.imdb.com/title/tt0368226/
      
      - title: Creating Rem Lezar
        author: Scott Zakarin
        year: 1989
        url: https://www.imdb.com/title/tt0369348/
      
      - title: Star Wars
        author: George Lucas
        year: 1977
        url: https://www.imdb.com/title/tt0076759/
      
      - title: The Empire Strikes Back
        author: Irvin Kershner
        year: 1980
        url: https://www.imdb.com/title/tt0080684/
      
      - title: The Terminator
        author: James Cameron
        year: 1984
        url: https://www.imdb.com/title/tt0088247/
      
      - title: "Terminator 2: Judgment Day"
        author: James Cameron
        year: 1991
        url: https://www.imdb.com/title/tt0103064/
        
      - title: "Wayne's World"
        author: Penelope Spheeris
        year: 1992
        url: https://www.imdb.com/title/tt0105793/
      
      - title: 12 Angry Men
        author: Sidney Lumet
        year: 1957
        url: https://www.imdb.com/title/tt0050083/
        
      - title: Airplane!
        author: Jim Abrahams
        year: 1980
        url: https://www.imdb.com/title/tt0080339/
      
      - title: Toy Story
        author: John Lasseter
        year: 1995
        url: https://www.imdb.com/title/tt0080339/
      
      - title: A Bugs's Life
        author: John Lasseter
        year: 1998
        url: https://www.imdb.com/title/tt0120623/
        
      - title: The Incredibles
        author: Brad Bird
        year: 2004
        url: https://www.imdb.com/title/tt0317705/
      
      - title: Up
        author: Pete Docter
        year: 2009
        url: https://www.imdb.com/title/tt1049413/
      
      - title: Coco
        author: Lee Unkrich
        year: 2017
        url: https://www.imdb.com/title/tt2380307/
      
      - title: Inception
        author: Christopher Nolan
        year: 2010
        url: https://www.imdb.com/title/tt1375666/
      
      - title: Alien
        author: Ridley Scott
        year: 1979
        url: https://www.imdb.com/title/tt0078748/
      
      - title: Blade Runner
        author: Ridley Scott
        year: 1982
        url: https://www.imdb.com/title/tt0083658/
      
      - title: El agente topo
        author: Maite Alberdi
        year: 2020
        url: https://www.imdb.com/title/tt0083658/
        
      - title: The Good, the Bad and the Ugly
        author: Sergio Leone
        year: 1966
        url: https://www.imdb.com/title/tt0060196/
    
    
  - name: Books
    title: Books I love
    id: books
    
    collection:
      - title: The Apple II Age
        author: Laine Nooney
        year: 2023
        url: https://www.amazon.com/Apple-II-Age-Computer-Personal/dp/0226816524
      
      - title: Make Something Wonderful
        author: Steve Jobs Archive
        year: 2023
        url: https://stevejobsarchive.com/book
      
      - title: What If?
        author: Randall Munroe
        year: 2014
        url: https://en.wikipedia.org/wiki/What_If%3F_(book)
        size: [ 214, 300 ]
      
      - title: Ask Iwata
        author: Satoru Iwata
        year: 2021
        url: https://www.amazon.com/Ask-Iwata-Wisdom-Nintendos-Legendary/dp/197472154X
      
      - title: "Jacked: The Outlaw Story of Grand Theft Auto"
        author: David Kushner
        year: 2012
        url: https://en.wikipedia.org/wiki/Jacked%3A_The_Outlaw_Story_of_Grand_Theft_Auto
      
      - title: How To
        author: Randall Munroe
        year: 2019
        url: https://en.wikipedia.org/wiki/How_To_(book)
        size: [ 234, 300 ]
      
      - title: The Design of Everyday Things
        author: Donald Norman
        year: 1988
        url: https://en.wikipedia.org/wiki/The_Design_of_Everyday_Things
      
      - title: Masters of Doom
        author: David Kushner
        year: 2003
        url: https://en.wikipedia.org/wiki/Masters_of_Doom
        size: [ 195, 300 ]
      
      - title: "Inside Out: A Personal History of Pink Floyd"
        author: Nick Mason
        year: 2004
        url: https://en.wikipedia.org/wiki/Inside_Out%3A_A_Personal_History_of_Pink_Floyd
      
      - title: Brief Answers to the Big Questions
        author: Stephen Hawking
        year: 2018
        url: https://en.wikipedia.org/wiki/Brief_Answers_to_the_Big_Questions
      
      - title: NASA Graphics Standards Manual
        author: NASA
        year: 1976
        url: https://www.nasa.gov/image-article/nasa-graphics-standards-manual/
      
      - title: Steve Jobs
        author: Walter Isaacson
        year: 2011
        url: https://en.wikipedia.org/wiki/Steve_Jobs_(book)
      
      - title: "Game Engine Black Book: DOOM"
        author: Fabien Sanglard
        year: 2018
        url: https://fabiensanglard.net/gebbdoom/
        size: [ 242, 300 ]
        
      - title: "Saucerful of Secrets: The Pink Floyd Odyssey"
        author: Nicholas Schaffner
        year: 1991
        url: https://www.amazon.com/Saucerful-Secrets-Pink-Floyd-Odyssey/dp/0385306849
        size: [ 197, 300 ]
      
      - title: Macintosh Human Interface Guidelines
        author: Apple
        year: 1993
        url: https://www.amazon.com/Saucerful-Secrets-Pink-Floyd-Odyssey/dp/0385306849
        size: [ 214, 300 ]
      
        
  - name: Games
    title: Games I love
    id: games
    
    collection:
      # NES
      
      - title: "Super Mario Bros."
        author: Nintendo
        year: 1985
        url: https://en.wikipedia.org/wiki/Super_Mario_Bros.
        size: [ 198, 300 ]
      
      - title: Duck Hunt
        author: Nintendo
        year: 1984
        url: https://en.wikipedia.org/wiki/Duck_Hunt
        size: [ 192, 300 ]
      
      - title: Soccer
        author: Nintendo
        year: 1985
        url: https://en.wikipedia.org/wiki/Soccer_%281985_video_game%29
        size: [ 192, 300 ]
        
      - title: Galaga
        author: Namco
        year: 1981
        url: https://en.wikipedia.org/wiki/Galaga
        size: [ 222, 300 ]
        
      - title: Nuts and Milk
        author: Hudson Soft
        year: 1983
        url: https://en.wikipedia.org/wiki/Nuts_%26_Milk
        size: [ 198, 300 ]
        
      # PlayStation
      
      - title: Crash Bandicoot
        author: Naughty Dog
        year: 1996
        url: https://en.wikipedia.org/wiki/Crash_Bandicoot_(video_game)
      
      - title: "Crash Bandicoot: Warped"
        author: Naughty Dog
        year: 1998
        url: https://en.wikipedia.org/wiki/Crash_Bandicoot:_Warped
      
      - title: Crash Team Racing
        author: Naughty Dog
        year: 1999
        url: https://en.wikipedia.org/wiki/Crash_Team_Racing
        
      - title: "Need for Speed: High Stakes"
        author: Electronic Arts
        year: 1999
        url: https://en.wikipedia.org/wiki/Need_for_Speed%3A_High_Stakes
      
      - title: Jackie Chan Stuntmaster
        author: Radical Entertainment
        year: 2000
        url: https://en.wikipedia.org/wiki/Jackie_Chan_Stuntmaster

      - title: "Legacy of Kain: Soul Reaver"
        author: Crystal Dynamics
        year: 1999
        url: https://en.wikipedia.org/wiki/Legacy_of_Kain%3A_Soul_Reaver
        
      - title: "Need for Speed: Porsche Unleashed"
        author: Electronic Arts
        year: 2000
        url: https://en.wikipedia.org/wiki/Need_for_Speed%3A_Porsche_Unleashed
        
      - title: "Rayman 2: The Great Escape"
        author: Ubisoft
        year: 1999
        url: https://en.wikipedia.org/wiki/Rayman_2:_The_Great_Escape
        
      - title: "Star Wars: Episode I – The Phantom Menace"
        author: Big Ape Productions
        year: 1999
        url: https://en.wikipedia.org/wiki/Star_Wars:_Episode_I_–_The_Phantom_Menace_(video_game)
      
      - title: "Bugs Bunny: Lost in Time"
        author: Behaviour Interactive
        year: 1999
        url: https://en.wikipedia.org/wiki/Bugs_Bunny%3A_Lost_in_Time
      
      # PSP
      
      - title: "Grand Theft Auto: Vice City Stories"
        author: Rockstar Games
        year: 2006
        url: https://en.wikipedia.org/wiki/Grand_Theft_Auto:_Vice_City_Stories
        size: [ 177, 300 ]
        
      - title: Gran Turismo
        author: Polyphony Digital
        year: 2009
        url: https://en.wikipedia.org/wiki/Gran_Turismo_(2009_video_game)
        size: [ 177, 300 ]
      
      # PC
      
      - title: Grand Theft Auto III
        author: DMA Design
        year: 2001
        url: https://en.wikipedia.org/wiki/Grand_Theft_Auto_III
        size: [ 213, 300 ]
      
      - title: "Grand Theft Auto: Vice City"
        author: Rockstar North
        year: 2002
        url: https://en.wikipedia.org/wiki/Grand_Theft_Auto%3A_Vice_City
        size: [ 200, 300 ]
      
      - title: "Grand Theft Auto: San Andreas"
        author: Rockstar North
        year: 2004
        url: https://en.wikipedia.org/wiki/Grand_Theft_Auto:_San_Andreas
        size: [ 200, 300 ]
        
      - title: Grand Theft Auto IV
        author: Rockstar North
        year: 2008
        url: https://en.wikipedia.org/wiki/Grand_Theft_Auto_IV
        size: [ 200, 300 ]
      
      - title: Grand Theft Auto V
        author: Rockstar North
        year: 2013
        url: https://en.wikipedia.org/wiki/Grand_Theft_Auto_V
        size: [ 213, 300 ]
      
      - title: Doom
        author: id Software
        year: 1993
        url: https://en.wikipedia.org/wiki/Doom_(1993_video_game)
        size: [ 198, 300 ]
        
      - title: Quake II
        author: id Software
        year: 1997
        url: https://en.wikipedia.org/wiki/Quake_II
        size: [ 198, 300 ]
      
      - title: Quake III Arena
        author: id Software
        year: 1999
        url: https://en.wikipedia.org/wiki/Quake_III_Arena
        size: [ 198, 300 ]
      
      - title: Doom 3
        author: id Software
        year: 2004
        url: https://en.wikipedia.org/wiki/Doom_3
        size: [ 198, 300 ]
      
      - title: Quake 4
        author: Raven Software
        year: 2005
        url: https://en.wikipedia.org/wiki/Quake_4
        size: [ 198, 300 ]

      - title: Half-Life
        author: Valve Software
        year: 2005
        url: https://en.wikipedia.org/wiki/Half-Life_(video_game)
        size: [ 198, 300 ]
      
      - title: Portal
        author: Valve Software
        year: 2007
        url: https://en.wikipedia.org/wiki/Portal_(video_game)
        size: [ 198, 300 ]
      
      - title: Portal 2
        author: Valve Software
        year: 2011
        url: https://en.wikipedia.org/wiki/Portal_2
        size: [ 198, 300 ]
      
      - title: The Sims
        author: Maxis
        year: 2000
        url: https://en.wikipedia.org/wiki/The_Sims_(video_game)
        size: [ 198, 300 ]
      
      - title: RollerCoaster Tycoon
        author: Chris Sawyer
        year: 1999
        url: https://en.wikipedia.org/wiki/RollerCoaster_Tycoon_(video_game)
        size: [ 198, 300 ]
        
      - title: "The Simpsons: Hit & Run"
        author: Radical Entertainment
        year: 2003
        url: https://en.wikipedia.org/wiki/The_Simpsons:_Hit_&_Run
        size: [ 198, 300 ]
      
      - title: "Halo: Combat Evolved"
        author: Bungie
        year: 2001
        url: https://en.wikipedia.org/wiki/Halo:_Combat_Evolved
        size: [ 198, 300 ]
      
      - title: "Halo: Reach"
        author: Bungie
        year: 2010
        url: https://en.wikipedia.org/wiki/Halo:_Reach
        size: [ 198, 300 ]
      
      # Gameboy
      
      - title: Tetris
        author: Nintendo
        year: 1989
        url: https://en.wikipedia.org/wiki/Tetris_%28Game_Boy_video_game%29
      
      # Gameboy Color
      
      - title: Donkey Kong Country
        author: Rare
        year: 1994
        url: https://en.wikipedia.org/wiki/Donkey_Kong_Country
      
      - title: Conker’s Pocket Tales
        author: Rare
        year: 1994
        url: https://en.wikipedia.org/wiki/Conker%27s_Pocket_Tales
      
      - title: "Game & Watch Gallery 2"
        author: Nintendo
        year: 1997
        url: https://en.wikipedia.org/wiki/Game_%26_Watch_Gallery_2
      
      - title: Tetris DX
        author: Nintendo
        year: 1998
        url: https://tetris.wiki/Tetris_DX
      
      # Gameboy Advance
      
      - title: "Pokémon LeafGreen"
        author: Game Freak
        year: 2004
        url: https://en.wikipedia.org/wiki/Pokémon_FireRed_and_LeafGreen
        
      - title: "Mario Kart: Super Circuit"
        author: Nintendo
        year: 2001
        url: https://en.wikipedia.org/wiki/Mario_Kart:_Super_Circuit
      
      - title: "The Legend of Zelda: The Minish Cap"
        author: Nintendo
        year: 2004
        url: https://en.wikipedia.org/wiki/The_Legend_of_Zelda%3A_The_Minish_Cap
      
      - title: "Tony Hawk’s Pro Skater 2"
        author: Neversoft
        year: 2000
        url: https://en.wikipedia.org/wiki/Tony_Hawk's_Pro_Skater_2
      
      # GameCube
      
      - title: "Luigi’s Mansion"
        author: Nintendo
        year: 2001
        url: https://en.wikipedia.org/wiki/Mario_Kart:_Super_Circuit
        size: [ 213, 300 ]
      
      # Nintendo DS
      
      - title: Mario Kart 7
        author: Nintendo
        year: 2011
        url: https://en.wikipedia.org/wiki/Mario_Kart_7
        size: [ 330, 300 ]
      
      - title: "Luigi’s Mansion: Dark Moon"
        author: Nintendo
        year: 2013
        url: https://en.wikipedia.org/wiki/Luigi%27s_Mansion:_Dark_Moon
        size: [ 330, 300 ]
      
      # Wii U
      
      - title: Mario Kart 8
        author: Nintendo
        year: 2014
        url: https://en.wikipedia.org/wiki/Mario_Kart_8
        size: [ 213, 300 ]
      
      - title: "Captain Toad: Treasure Tracker"
        author: Nintendo
        year: 2014
        url: https://en.wikipedia.org/wiki/Captain_Toad%3A_Treasure_Tracker
        size: [ 213, 300 ]
      
      - title: "Donkey Kong Country: Tropical Freeze"
        author: Retro Studios
        year: 2014
        url: https://en.wikipedia.org/wiki/Donkey_Kong_Country:_Tropical_Freeze
        size: [ 213, 300 ]
      
      - title: "The Legend of Zelda: Breath of the Wild"
        author: Nintendo
        year: 2017
        url: https://en.wikipedia.org/wiki/The_Legend_of_Zelda:_Breath_of_the_Wild
        size: [ 213, 300 ]
      
      # Switch
      
      - title: Super Mario Odyssey
        author: Nintendo
        year: 2017
        url: https://en.wikipedia.org/wiki/Super_Mario_Odyssey
        size: [ 183, 300 ]
      
      - title: "Animal Crossing: New Horizons"
        author: Nintendo
        year: 2020
        url: https://en.wikipedia.org/wiki/Animal_Crossing:_New_Horizons
        size: [ 183, 300 ]
      
      - title: Luigi’s Mansion 3
        author: Nintendo
        year: 2019
        url: https://en.wikipedia.org/wiki/Luigi%27s_Mansion_3
        size: [ 183, 300 ]
      
      - title: "The Legend of Zelda: Link’s Awakening"
        author: Nintendo
        year: 2019
        url: https://en.wikipedia.org/wiki/The_Legend_of_Zelda:_Link%27s_Awakening_(2019_video_game)
        size: [ 183, 300 ]
      
      - title: Metroid Dread
        author: Nintendo
        year: 2021
        url: https://en.wikipedia.org/wiki/Metroid_Dread
        size: [ 183, 300 ]
      
      # Indie
      
      - title: Machinarium
        author: Amanita Design
        year: 2009
        url: https://en.wikipedia.org/wiki/Machinarium
        size: [ 198, 300 ]
      
      - title: Minecraft
        author: Mojang Studios
        year: 2009
        url: https://en.wikipedia.org/wiki/Minecraft
        size: [ 198, 300 ]
      
      - title: "Amnesia: The Dark Descent"
        author: Frictional Games
        year: 2010
        url: https://en.wikipedia.org/wiki/Amnesia:_The_Dark_Descent
        size: [ 198, 300 ]
      
      - title: The Stanley Parable
        author: Galactic Cafe
        year: 2013
        url: https://en.wikipedia.org/wiki/The_Stanley_Parable
        size: [ 198, 300 ]
      
      - title: "The Binding of Isaac: Rebirth"
        author: Nicalis
        year: 2014
        url: https://en.wikipedia.org/wiki/The_Binding_of_Isaac:_Rebirth
        size: [ 198, 300 ]
      
      - title: Roundabout
        author: No Goblin
        year: 2014
        url: https://en.wikipedia.org/wiki/Roundabout_(video_game)
        size: [ 198, 300 ]
      
      - title: Undertale
        author: Toby Fox
        year: 2015
        url: https://en.wikipedia.org/wiki/Undertale
        size: [ 198, 300 ]
      
      - title: Firewatch
        author: Campo Santo
        year: 2016
        url: https://en.wikipedia.org/wiki/Firewatch
        size: [ 198, 300 ]
        
      - title: Untitled Goose Game
        author: House House
        year: 2019
        url: https://en.wikipedia.org/wiki/Untitled_Goose_Game
        size: [ 198, 300 ]
      
      # Mac
      
      - title: Uninvited
        author: John Calhoun
        year: 1986
        url: https://en.wikipedia.org/wiki/Uninvited_(video_game)
        size: [ 240, 300 ]
        
      - title: Glider
        author: John Calhoun
        year: 1988
        url: https://en.wikipedia.org/wiki/Glider_(video_game)
        size: [ 240, 300 ]
      
      

