import { View } from "cacao/ui";

class LibraryItemCell extends View {
  
  static get style(){
    return {
      music: "music"
    }
  }
  
  constructor(){
    super();
    this.node.className = "cell";
  }
  
  configure(configuration, options){
    const { image, style, size } = configuration;
    
    this.src = image;
    this.style = style;
    
    this.node.innerHTML = `<div class="image"></div>`;
    
    if (size) {
      const [ width, height ] = size;
      this.node.style.width = `${width}px`;
      this.node.style.height = `${height}px`;
    }
    
    const { didSelect } = options;
    this.node.onclick = didSelect;
    
    this.node.classList.add("loading");
  }
  
  didAppear(){
    const { src, style } = this;
    
    const pictureMarkup = `
      <picture class="image">
        <source type="image/jpeg" srcset="${src}" />
        <img src="${src}" />
      </picture>
    `;
    
    this.node.innerHTML = pictureMarkup;
    
    if (style === LibraryItemCell.style.music) {
      const reflectionNode = document.createElement("span");
      reflectionNode.className = "reflection";
      reflectionNode.innerHTML = pictureMarkup;
      
      this.node.appendChild(reflectionNode);
    }
    
    const didLoad = () => {
      this.node.classList.remove("loading");
    };
    
    const img = this.node.querySelector("img");
    img.onload = didLoad;
  }
  
  didDisappear(){
    this.node.innerHTML = "";
  }
  
}

export default LibraryItemCell;
