class SpringboardLayout {
  
  static layout({ itemCount, containerNode } = options){
    const itemSize = { width: 108, height: 142 };
    const gap = 32;
    const padding = { top: 32, left: 32, right: 32, bottom: 32 * 2 + 8 };
    const containerNodeSize = containerNode.getBoundingClientRect();
    const containerSize = {
      width: containerNodeSize.width - padding.left - padding.right,
      height: containerNodeSize.height - padding.top - padding.bottom
    };
    
    const minimumLines = 1, minimumItemsPerLine = 1;
    
    const totalItemWidth = itemSize.width + gap;
    const totalItemHeight = itemSize.height + gap;
    
    const itemsPerLine = Math.max(Math.floor((containerSize.width + gap) / totalItemWidth), minimumItemsPerLine);
    const lines = Math.max(Math.floor((containerSize.height + gap) / totalItemHeight), minimumLines);
    
    const maximumItemsPerLine = 5;
    const constrainedItemsPerLine = Math.min(itemsPerLine, maximumItemsPerLine);
    
    const maximumLines = Math.ceil(itemCount / constrainedItemsPerLine);
    
    const contrainedItemSize = {
      width: (containerSize.width - (constrainedItemsPerLine - 1) * gap) / constrainedItemsPerLine
    };
    
    const constrainedLines = Math.min(lines, maximumLines);
    
    return new SpringboardLayout(constrainedLines, constrainedItemsPerLine);
  }
  
  constructor(rows, columns){
    this.rows = rows;
    this.columns = columns;
  }
  
  isEquivalent(otherLayout){
    if (this == otherLayout) {
      return true;
    }
    
    if (this.rows == otherLayout.rows && this.columns == otherLayout.columns) {
      return true;
    }
    
    return false;
  }
  
}

export default SpringboardLayout;
