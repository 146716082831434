import { View } from "cacao/ui";
import { Rect } from "cacao/graphics";

import LibraryNavigationBar from "./LibraryNavigationBar";
import LibraryItemDetailView from "./LibraryItemDetailView";
import LibraryItemCell from "./LibraryItemCell";

import { imageForItemInCollection } from "../assets/index";

class LibraryCollectionView extends View {
  
  constructor(data){
    super();
    
    this.data = data;
    this.node.className = "library-collection-view";
    
    this.node.classList.add(data.id);
    
    // Navigation:
    const navigationBar = new LibraryNavigationBar();
    navigationBar.title = data.title;
    
    this.navigationBar = navigationBar;
    
    this.addSubview(navigationBar);
    
    // Grid:
    const collectionWrapperView = new View();
    collectionWrapperView.node.className = "collection-wrapper";
    
    const collectionContainerView = new View();
    collectionContainerView.node.className = "collection-container";
    
    collectionWrapperView.addSubview(collectionContainerView);
    
    this.cells = data.collection.map((item, idx) => {
      const cell = new LibraryItemCell();
      
      cell.configure({ image: imageForItemInCollection(item.title, data.id), style: data.id, size: item.size }, {
        didSelect: (event) => {
          this.didSelectItem(idx);
        }
      });
      
      collectionContainerView.addSubview(cell);
      
      return cell;
    });
    
    this.collectionWrapperView = collectionWrapperView;
    this.collectionContainerView = collectionContainerView;
    
    this.addSubview(collectionWrapperView);
    
    // First, last:
    const setReferenceWidthForPropertyNamed = (item, prop) => {
      const { size } = item;
      if (size) {
        const [ width ] = size;
        collectionWrapperView.node.style.setProperty(prop, `${width}px`);
      }
    };
    
    setReferenceWidthForPropertyNamed(data.collection[0], "--first-item-width");
    setReferenceWidthForPropertyNamed(data.collection.slice(-1)[0], "--last-item-width");
    
    // Detail:
    const itemDetailView = new LibraryItemDetailView();
    this.itemDetailView = itemDetailView;
    
    this.addSubview(itemDetailView);
    
    // Handle scroll:
    const handleScroll = () => {
      this.invalidate();
    };
    collectionContainerView.node.addEventListener("scroll", throttle(handleScroll, 250));
    
    this.invalidate();
  }
  
  invalidate(){
    const frame = () => {
      this.configure();
      this.request = undefined;
    };
    
    if (this.request) {
      return;
    }
    
    this.request = window.requestAnimationFrame(frame);
  }
  
  configure(){
    const wrapper = this.collectionWrapperView.node;
    
    const wrapperRect = Rect.fromDOMRect(wrapper.getBoundingClientRect());
    const wrapperCenterX = wrapperRect.midX;
    
    let closestIndex = 0;
    let closestDistance = Number.MAX_VALUE;
    
    const visibilityInset = wrapperRect.width * -1.0;
    const visibilityRect = wrapperRect.insetBy(visibilityInset, 0);
    visibilityRect.origin.y = -Infinity;
    visibilityRect.size.height = Infinity;
    
    this.cells.forEach((cell, idx) => {
      const itemRect = Rect.fromDOMRect(cell.node.getBoundingClientRect());
      const distance = Math.abs(itemRect.midX - wrapperCenterX);
      
      if (distance < closestDistance) {
        closestDistance = distance;
        closestIndex = idx;
      }
      
      const isVisible = visibilityRect.intersects(itemRect);
      
      if (isVisible != !!cell.isVisible) {
        cell.isVisible = isVisible;
        
        if (isVisible) {
          cell.didAppear();
        } else {
          cell.didDisappear();
        }
      }
    });
    
    if (this.focusedIndex !== closestIndex) {
      const item = this.data.collection[closestIndex];
      if (item) {
        this.itemDetailView.configure(item, { animated: false });
      }
      
      this.focusedItemIndex = closestIndex;
    }
  }
  
  didSelectItem(idx){
    if (idx == this.focusedItemIndex) {
      this.openInformationAtIndex(idx);
    } else {
      this.scrollToItemAtIndex(idx);
    }
  }
  
  openInformationAtIndex(idx){
    const item = this.data.collection[idx];
    
    window.app.handleURL(item.url, { animated: true });
  }
  
  scrollToItemAtIndex(idx){
    const node = this.cells[idx].node;
    
    node.scrollIntoView({ behavior: "smooth", inline: "center" });
  }
  
}

function throttle(callbackFn, limit) {
  let wait = false;                  
  return function () {              
    if (!wait) {                  
      callbackFn.call();           
      wait = true;               
      setTimeout(function () {   
        wait = false;          
      }, limit);
    }
  }
}

export default LibraryCollectionView;
