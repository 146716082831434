import { View } from "cacao/ui";
import MarqueeLabel from "./MarqueeLabel";

class LibraryItemDetailView extends View {
  
  constructor(){
    super();
    
    this.node.className = "detail";
    
    // Title:
    const titleNode = document.createElement("span");
    titleNode.className = "title";
    
    const titleMarquee = new MarqueeLabel();
    titleNode.appendChild(titleMarquee.node);
    
    this.titleNode = titleNode;
    this.titleMarquee = titleMarquee;
    
    this.node.appendChild(titleNode);
    
    // Author:
    const authorNode = document.createElement("span");
    authorNode.className = "author";
    
    this.authorNode = authorNode;
    this.node.appendChild(authorNode);
    
    // Year:
    const yearNode = document.createElement("span");
    yearNode.className = "year";
    
    this.yearNode = yearNode;
    this.node.appendChild(yearNode);
  }
  
  configure(configuration, { animated = true} = options){
    const { title, author, year, url } = configuration;
    
    this.titleMarquee.text = title;
    this.authorNode.textContent = author;
    this.yearNode.textContent = year;
  }
  
}

export default LibraryItemDetailView;
