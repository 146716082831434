import * as applianceIcons from "/assets/img/app/*";
import * as chrome from "/assets/img/*";
import * as collectionImages from "/assets/img/collections/**/*";

import DirectoryData from "./DirectoryData";
import Preloader from "./Preloader";
import slugify from "/src/slugify";

const imageForItemInCollection = (title, collectionIdentifier) => {
  const imagesForCollection = collectionImages[collectionIdentifier];
  
  if (!imagesForCollection) {
    console.warn(`Unknown collection for collection "${collectionIdentifier}"`);
    return undefined;
  }
  
  const key = `${slugify(title)}.jpg`;
  const image = imagesForCollection[key];
  
  if (!image) {
    console.warn(`Unknown image for key "${key}"`);
    return undefined;
  }
  
  return image;
};

export {
  applianceIcons,
  chrome,
  imageForItemInCollection,
  Preloader,
  DirectoryData
};
