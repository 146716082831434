import { View } from "cacao/ui";

class PageControl extends View {
  
  static indicatorClassName = "page-indicator";
  static selectedIndicatorClassName = "selected";
  
  constructor(){
    super();
    
    this._currentPage = 0;
    this._numberOfPages = 1;
    this._hidesForSinglePage = true;
    
    this.node.className = "page-control";
    this.configure();
  }
  
  configure(){
    const { numberOfPages, currentPage } = this;
    const indicators = [];
    
    for (let idx = 0; idx < numberOfPages; idx +=1 ) {
      const indicator = document.createElement("div");
      indicator.className = PageControl.indicatorClassName;
      
      indicators.push(indicator);
    }
    
    const fragment = document.createDocumentFragment();
    indicators.forEach(indicator => fragment.appendChild(indicator));
    
    this.node.innerHTML = "";
    this.node.appendChild(fragment);
    this.node.style.visibility = (this.hidesForSinglePage && numberOfPages == 1) ? "hidden" : "";
    
    this._indicators = indicators;
    this.selectionDidChange();
  }
  
  selectionDidChange(){
    const indicator = this._indicators[this.currentPage];
    
    if (!indicator) {
      return;
    }
    
    if (this._selectedIndicator) {
      this._selectedIndicator.classList.remove(PageControl.selectedIndicatorClassName);
    }
    
    indicator.classList.add(PageControl.selectedIndicatorClassName);
    
    this._selectedIndicator = indicator;
  }
  
  set hidesForSinglePage(flag){
    if (flag != this._hidesForSinglePage) {
      this._hidesForSinglePage = flag;
      this.configure();
    }
  }
  
  get hidesForSinglePage(){
    return this._hidesForSinglePage;
  }
  
  set numberOfPages(number){
    if (number != this._numberOfPages) {
      this._numberOfPages = number;
      this.configure();
    }
  }
  
  get numberOfPages(){
    return this._numberOfPages;
  }
  
  set currentPage(page){
    if (page != this._currentPage) {
      this._currentPage = page;
      this.selectionDidChange();
    }
  }
  
  get currentPage(){
    return this._currentPage;
  }
}

export default PageControl;
