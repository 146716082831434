import { View } from "cacao/ui";

class LibraryNavigationBar extends View {
  
  makeNode(document){
    return document.createElement("header");
  }
  
  constructor(){
    super();
    
    const titleNode = document.createElement("span");
    titleNode.className = "title";
    
    this.titleNode = titleNode;
    
    this.node.appendChild(titleNode);
    
    const closeNode = document.createElement("button");
    closeNode.className = "close-button";
    closeNode.onclick = (event) => {
      const { didSelectClose } = this;
      if (didSelectClose){
        didSelectClose(event);
      }
    };
    
    this.closeNode = closeNode;
    
    this.node.appendChild(closeNode);
  }
  
  set title(text){
    this.titleNode.textContent = text;
  }
  
  get title(){
    return this.titleNode.textContent;
  }
  
}

export default LibraryNavigationBar;
