import { View } from "cacao/ui";
import SpringboardLayout from "./SpringboardLayout";
import SpringboardApplianceCell from "./SpringboardApplianceCell";
import PageControl from "./PageControl";

class SpringboardView extends View {
  
  static get animation(){
    return { in: "animate-in", out: "animate-out" };
  };
  
  constructor(appliances){
    super();
    
    this.appliances = appliances;
    this.node.className = "springboard-view";
    
    // Container:
    const containerView = new View();
    containerView.node.className = "springboard-container";
    containerView.node.addEventListener("scroll", this.containerDidScroll.bind(this));
    
    this.containerView = containerView;
    this.addSubview(containerView);
    
    // Page control:
    const pageControl = new PageControl();
    
    this.pageControl = pageControl;
    this.addSubview(pageControl);
    
    // Configure:
    this.configure();
    
    // Re-layout on resize:
    window.addEventListener("resize", (event) => this.configure());
    window.requestAnimationFrame(() => this.configure());
  }
  
  configure(){
    const { appliances, containerView } = this;
    const layout = SpringboardLayout.layout({ itemCount: appliances.length, containerNode: containerView.node });
    
    if (this.layout && this.layout.isEquivalent(layout)) {
      return;
    }
    
    const { rows, columns } = layout;
    containerView.node.style.setProperty("--rows", rows);
    containerView.node.style.setProperty("--columns", columns);
    containerView.node.innerHTML = "";
    
    this.layout = layout;
    
    const appliancesPerPage = rows * columns;
    const pagesCount = Math.ceil(appliances.length / appliancesPerPage);
    
    for (let page = 0; page < pagesCount; page += 1) {
      const offset = page * appliancesPerPage;
      const pageView = new View();
      pageView.node.className = "page-container";
      
      appliances.slice(offset, offset + appliancesPerPage).forEach(appliance => {
        const cell = new SpringboardApplianceCell();
        cell.configure(appliance, {
          didSelect: (event) => {
            this.selectAppliance(appliance, cell, event);
          }
        });
        
        pageView.addSubview(cell);
      });
      
      containerView.addSubview(pageView);
    }
    
    this.pageControl.numberOfPages = pagesCount;
  }
  
  // - Cell selection.
  
  selectAppliance(appliance, cell, event){
    const { didSelectAppliance } = this;
    
    if (didSelectAppliance) {
      didSelectAppliance(appliance, event);
    }
    
    if (cell) {
      const deselectSeconds = 0.35;
      const { classList } = cell.node;
      classList.add("selected");
          
      setTimeout(() => {
        classList.remove("selected");
      }, deselectSeconds * 1000);
    }
  }
  
  // - Scroll
  
  containerDidScroll(){
    const { pageControl, containerView } = this;
    const { node } = containerView;
    
    const currentPage = Math.round(node.scrollLeft / node.getBoundingClientRect().width);
    pageControl.currentPage = currentPage;
  }
  
  // - Animations
  
  animate(animationName, options){
    const { currentPage } = this.pageControl;
    const pageContainer = this.node.querySelectorAll(".page-container")[currentPage];
    const icons = pageContainer.querySelectorAll(".cell");
    const auxiliary = [ this.pageControl.node ];
    
    const pageContainerRect = pageContainer.getBoundingClientRect();
    
    const centerX = pageContainerRect.width / 2;
    const centerY = pageContainerRect.height / 2;
    const translationDistance = 300; // Distance to move the icons
    
    Array.from(icons).concat(auxiliary).forEach(icon => {
      const rect = icon.getBoundingClientRect();
      const iconX = rect.left + rect.width / 2;
      const iconY = rect.top + rect.height / 2;
      
      const dx = iconX - centerX;
      const dy = iconY - centerY;
      const length = Math.sqrt(dx * dx + dy * dy);
      const directionX = dx / length;
      const directionY = dy / length;
      
      const finalX = iconX + translationDistance * directionX;
      const finalY = iconY + translationDistance * directionY;
      
      const keyframes = [
          { transform: new DOMMatrix() },
          { transform: new DOMMatrix().translate(finalX - iconX, finalY - iconY) }
      ];
      
      if (animationName !== SpringboardView.animation.out) {
        keyframes.reverse();
      }
      
      icon.animate(keyframes, options);
    });
  }
  
}

export default SpringboardView;
