
class Preloader {
  
  async fonts(){
    const getCookie = function(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length == 2) return parts.pop().split(";").shift();
    };
    
    if (getCookie("fontsLoaded")) {
      return;
    }
    
    if ("fonts" in document) {
      await document.fonts.ready;
      
      const expires = new Date(+new Date() + (7 * 24 * 60 * 60 * 1000)).toUTCString();
      document.cookie = "fontsLoaded=true; expires=" + expires;
    }
  }
  
  async imagesContainedInElement(element){
    const images = element.querySelectorAll("img");
    
    const promises = Array.from(images).map(img => {
      return new Promise((resolve, reject) => {
        if (img.complete) {
          // If the image is already loaded
          resolve();
        } else {
          // Add event listeners for load and error
          img.addEventListener("load", resolve);
          img.addEventListener("error", reject);
        }
      });
    });
    
    return await Promise.all(promises);
  }
  
  
}

export default Preloader;
