import { View } from "cacao/ui";
import { applianceIcons } from "../assets/index";

class SpringboardApplianceCell extends View {
  
  constructor(){
    super();
    
    this.node.className = "cell";
    
    // Just adding this will enable :active on touch.
    this.node.addEventListener("touchstart", () => {});
  }
  
  configure(appliance, options){
    const { didSelect } = options;
    
    const identifier = `${appliance.title.toLowerCase()}.png`;
    const image = applianceIcons[identifier];
    
    this.node.onclick = didSelect;
    this.node.innerHTML = `
      <picture class="icon">
        <source srcset="${image}" type="image/png" />
        <img src="${image}" />
      </picture>
      <div class="label">${appliance.title}</div>
    `;
  }
  
}

export default SpringboardApplianceCell;
