import { View } from "cacao/ui";

import RootHeaderView from "./RootHeaderView";
import SpringboardView from "./SpringboardView";

class RootView extends View {
  springboardView;
  presentedView;
  
  static defaultAnimationOptions = {
    duration: 300,
    easing: "ease-in-out"
  };
  
  constructor(data){
    super();
    
    this.node.className = "root-view";
    this.data = data;
    
    // Springboard:
    const springboardView = new SpringboardView(data.destinations);
    springboardView.didSelectAppliance = (appliance) => {
      this.notifyDidNavigate(appliance.url);
    };
    
    this.springboardView = springboardView;
    
    this.addSubview(springboardView);
    
    // Prepend header:
    const headerView = new RootHeaderView();
    springboardView.node.prepend(headerView.node);
    
    // Escape handler:
    document.addEventListener("keyup", event => {
      if (event.key === "Escape") {
        this.notifyDidNavigate("/");
      }
    });
  }
  
  notifyDidNavigate(url){
    const { didSelectURL } = this;
    if (didSelectURL) {
      didSelectURL(url);
    }
  }
  
  present(view, { animated = true } = options){
    if (this.presentation) {
      throw new Error("Attempted to present a view when already presenting");
      return;
    }
    
    // Wrapper:
    const presentationWrapper = new View();
    presentationWrapper.node.className = "presentation-wrapper";
    
    const spacerView = new View();
    spacerView.node.className = "spacer";
    
    presentationWrapper.addSubview(spacerView);
    presentationWrapper.addSubview(view);
    
    // Container that contains wrapper:
    const presentationContainer = new View();
    presentationContainer.node.className = "presentation-container";
    
    presentationContainer.addSubview(presentationWrapper);
    
    this.addSubview(presentationContainer);
    
    this.presentation = {
      presentationWrapper,
      presentationContainer
    };
    
    // Set up scroll:
    window.requestAnimationFrame(() => {
      presentationWrapper.node.scrollLeft = presentationWrapper.node.getBoundingClientRect().width;
      presentationWrapper.node.addEventListener("scroll", (event) => {
        const offset = presentationWrapper.node.scrollLeft;
        
        if (offset <= 1.0) {
          presentationWrapper.node.scrollLeft = 0.0;
          
          this.dismiss({ animated: false });
        } else {
          const { width } = presentationWrapper.node.getBoundingClientRect();
          
          // TODO: Prevent bouncing?
          if (offset > width) {
            // presentationWrapper.node.style.overflow = "hidden";
            // presentationWrapper.node.style.overflow = "";
            // presentationWrapper.node.scrollLeft = width;
          }
        }
      });
    });
    
    // Animate:
    const animate = () => {
      const options = RootView.defaultAnimationOptions;
      
      presentationWrapper.node.animate([
        {
          transform: new DOMMatrix().scale(0.25),
          opacity: 0
        },
        
        {
          transform: new DOMMatrix().scale(1)
        },
        
      ], options);
      
      this.springboardView.animate(SpringboardView.animation.out, options);
    };
    
    if (animated) {
      window.requestAnimationFrame(animate);
    }
    
  }
  
  dismiss({ animated = true } = options){
    if (!this.presentation) {
      return;
    }
    
    const { presentationContainer, presentationWrapper } =  this.presentation;
    this.presentation = undefined;
    
    const dismiss = () => {
      // Remove view:
      presentationContainer.removeFromSuperview();
      
      // Notify:
      this.notifyDidNavigate("/");
    };
    
    if (animated) {
      const options = RootView.defaultAnimationOptions;
      
      const animation = presentationWrapper.node.animate([
        {
          transform: new DOMMatrix().scale(1)
        },
        
        {
          transform: new DOMMatrix().scale(0.25),
          opacity: 0
        }
      ], options);
      
      this.springboardView.animate(SpringboardView.animation.in, options);
      
      animation.finished.then(dismiss);
    } else {
      dismiss();
    }
  }
  
  animateSpringboardIn(){
    const options = RootView.defaultAnimationOptions;
    
    const fade = this.springboardView.node.animate([
      { opacity: 0 },
      { opacity: 1 }
      
    ], options);
    
    this.springboardView.animate("animate-in", options);
  }
  
}

export default RootView;
